import React from 'react';
import styles from './DecorativeFiltersSidebar.module.scss';
import SearchFilter from '../search-filter/SearchFilter';
import { DecorativeProps } from './interfaces';
import { getMaterials } from './util';
import { FilterType } from '../../slab/collapse-filters-side-bar/filter-type.enum';

function createItem(
  name: string,
  i: number,
  isSelected: boolean,
  handleClick: () => void,
): React.ReactNode {
  return (
    <div className={styles.filterItem} key={i} onClick={handleClick}>
      <p className={isSelected ? styles.selected : ''} />
      {name}
    </div>
  );
}

const DecorativeFiltersSidebar: React.FC<DecorativeProps> = (props: DecorativeProps) => {
  return (
    <div className={styles.DecorativeFiltersSidebar}>
      <SearchFilter {...props} />

      <div className={styles.delimiter}></div>

      <p className={styles.head}>FILTER</p>

      {getMaterials(props, createItem)}

      <div className={styles.delimiter}></div>
      <div
        className={styles.filterItem}
        onClick={() => props.switchSelection(FilterType.IS_DEALER, '')}
      >
        <p className={props.filtersState[FilterType.IS_DEALER] ? styles.selected : ''} />
        Dealer
      </div>
    </div>
  );
};

export default DecorativeFiltersSidebar;

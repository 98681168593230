import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Highlights from '../components/shared/highlights/Highlights';
import { FinishInfo, Tile, Material, Slab } from '../components/shared/interfaces';
import { Modal } from '../components/shared/modal/Modal';
import ProductContainer from '../components/shared/product-container/ProductContainer';
import CollapseFiltersSideBar from '../components/slab/collapse-filters-side-bar/CollapseFiltersSideBar';
import { FilterType } from '../components/slab/collapse-filters-side-bar/filter-type.enum';
import MobileFiltersBtn from '../components/slab/mobile-filters/mobile-filters-btn/MobileFiltersBtn';
import MobileFilters from '../components/slab/mobile-filters/MobileFilters';
import {
  applyFilters,
  createSortFn,
  handleFilterUpdate,
  loadFilterState,
  saveFilterState,
} from '../components/slab/sort-filter/filter-utils';
import SortFilter from '../components/slab/sort-filter/SortFilter';
import DecorativeFiltersSidebar from '../components/tile/decorative-filters/DecorativeFiltersSidebar';
import SearchFilter from '../components/tile/search-filter/SearchFilter';
import { TileState } from '../components/tile/tile-state.interface';
import useFavourite from '../hooks/useFavourite';
import useViewport from '../hooks/useViewport';
import { SlugType } from './slug-type.interface';
import styles from './tile-collection-template.module.scss';

export const query = graphql`
  query($collectionId: ID!) {
    strapi {
      materials {
        id
        name
        sort_index
      }
      tilePage {
        decorativeTile_backgroundImage {
          url
          alternativeText
        }
        fieldTile_backgroundImage {
          alternativeText
          url
        }
        outdoorTile_backgroundImage {
          url
          alternativeText
        }
      }
      tiles(where: { tile_collection: { id: $collectionId } }) {
        leadTime: LeadTime
        range: Range
        isInStock: inStock
        isDealer: dealer
        externalLink: externalUrl
        id
        created_at
        name
        sortIndex
        tile_collection {
          name
        }
        description
        material {
          name
          id
        }
        decorative_materials {
          name
          id
        }
        color {
          id
          name
        }
        color_secondary {
          id
          name
        }
        color_tertiary {
          id
          name
        }
        images {
          url
          caption
        }
        preview {
          url
        }
        finishes: tile_finishes {
          finish {
            id
          }
          sizes
          image {
            url
          }
        }
      }
      finishes {
        name
        id
        is_textured
      }
    }
  }
`;
export interface TilesResponse {
  strapi: {
    tiles: Tile[];
    finishes: FinishInfo[];
    materials: Material[];
    tilePage?: any;
  };
}

let PAGE_SIZE = 12;
let CHANGE_PAGE_SIZE = false;

const TileCollectionTemplate: React.FC<Props> = ({
  data,
  pageContext: { description, slug, subtitle, title },
}: Props) => {
  const initialTiles = formatData(data, 'A - Z');
  const { isTablet } = useViewport();
  const favouriteAPI = useFavourite();

  const isDecorative = slug === SlugType.DECORATIVE;
  const isField = slug === SlugType.FIELD;
  const isOutdoor = slug === SlugType.OUTDOOR;

  const pageName = (isField && 'Tile_Field') || (isOutdoor && 'Tile_Outdoor') || 'Tile_Decorative';

  const filtersStoredState = loadFilterState(pageName);

  const setOffset = () => {
    setTimeout(() => {
      window.scrollTo({
        top: +window.sessionStorage.getItem('lastOffset'),
        behavior: 'instant',
      });
    }, 0);
  };

  useEffect(() => {
    // window.addEventListener('popstate', setOffset, false);
    return () => {
      window.sessionStorage.setItem('lastOffset', window.scrollY);
    };
  }, []);

  if (typeof window !== 'undefined' && window.location.hash) {
    const decodedHash = window.atob(window.location.hash.substring(1));

    const parsedDecodedHash = JSON.parse(decodedHash);

    try {
      filtersStoredState.filters = parsedDecodedHash.filtersState;
      filtersStoredState.sortState = parsedDecodedHash.sortState;
    } catch {
      filtersStoredState.filters = filtersStoredState.filters;
      filtersStoredState.sortState = filtersStoredState.sortState;
    }
  }

  const sortedInitialTiles = initialTiles.sort((a, b) => {
    if (a.sortIndex && b.sortIndex) {
      return a.sortIndex - b.sortIndex;
    }
    if (a.sortIndex) {
      return -1;
    }
    if (b.sortIndex) {
      return 1;
    }

    return 0;
  });

  const stateLoadedTiles = (tiles: (Tile | Slab)[]) =>
    tiles.sort((a, b) => {
      if (a.sortIndex && b.sortIndex) {
        return a.sortIndex - b.sortIndex;
      }
      if (a.sortIndex) {
        return -1;
      }
      if (b.sortIndex) {
        return 1;
      }

      return 0;
    });
  const initialState: TileState = {
    isFilterPopupOpen: false,
    filtersState: filtersStoredState.filters,
    tiles: initialTiles,
    sortState: filtersStoredState.sortState,
    loadedTiles: sortedInitialTiles.slice(0, PAGE_SIZE),
  };

  applyFilters(
    sortedInitialTiles,
    (t) => (initialState.tiles = t.tiles),
    initialState,
    isDecorative,
  );

  const [state, setState] = useState<TileState>(initialState);

  const updateState = (newState: TileState) => {
    const objToStringify = { sortState: {}, filtersState: {} };
    objToStringify.sortState = newState.sortState;
    objToStringify.filtersState = newState.filtersState;

    window.location.hash = btoa(JSON.stringify(objToStringify));

    saveFilterState(pageName, newState.filtersState, newState.sortState);
    setState(newState);
  };

  const clearFilter = (
    filter?: FilterType.MATERIAL | FilterType.FINISH | FilterType.COLOR | FilterType.DIMENSIONS,
  ) => {
    if (filter) {
      state.filtersState[filter] = [];
    } else {
      state.filtersState = {
        [FilterType.IS_IN_STOCK]: false,
        [FilterType.IS_DEALER]: false,
        [FilterType.SEARCH]: '',
        [FilterType.COLOR]: [],
        [FilterType.MATERIAL]: [],
        [FilterType.FINISH]: [],
        [FilterType.DIMENSIONS]: [],
      };
    }

    applyFilters(sortedInitialTiles, updateState, state, isDecorative);
  };

  const updateFilters = (filterType: FilterType, param: string, value?: string) =>
    handleFilterUpdate(
      filterType,
      param,
      sortedInitialTiles,
      updateState,
      state,
      value,
      isDecorative,
    );

  const switchFilterPopup = (): void =>
    updateState({
      ...state,
      isFilterPopupOpen: !state.isFilterPopupOpen,
    });

  const loadMore = (quantity: number) =>
    updateState({
      ...state,
      loadedTiles: state.tiles.slice(0, state.loadedTiles.length + quantity),
    });

  useEffect(() => {
    window.addEventListener('popstate', setOffset, false);
    updateState({ ...state, loadedTiles: stateLoadedTiles(state.tiles).slice(0, PAGE_SIZE) });

    return function () {
      // PAGE_SIZE = state.loadedTiles.length || 12;
      PAGE_SIZE =
        !state.loadedTiles.length || state.loadedTiles.length < 12 ? 12 : state.loadedTiles.length;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tiles]);

  {
    if (typeof window !== 'undefined') {
      window.onpopstate = () => {
        // PAGE_SIZE = state.loadedTiles.length || 12;
        PAGE_SIZE =
          !state.loadedTiles.length || state.loadedTiles.length < 12
            ? 12
            : state.loadedTiles.length;
        CHANGE_PAGE_SIZE = true;
      };
    }
  }

  let heroBackground: string;
  if (isField) {
    heroBackground = data.strapi.tilePage?.fieldTile_backgroundImage.url;
  } else if (isOutdoor) {
    heroBackground = data.strapi.tilePage?.outdoorTile_backgroundImage.url;
  } else {
    heroBackground = data.strapi.tilePage?.decorativeTile_backgroundImage.url;
  }

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <Layout
      isWhiteBackground={true}
      bottomFloatingComponent={
        isTablet && <MobileFiltersBtn name={'FILTER'} onClick={switchFilterPopup} />
      }
    >
      <SEO title={title} />
      <section className={styles.wrapper}>
        <section
          className={styles.highlightContainer}
          style={{
            backgroundImage: `url(${heroBackground})`,
          }}
        >
          <Highlights
            title={title}
            subtitle={subtitle}
            description={description}
            isDecorative={isDecorative}
            isOutdoor={isOutdoor}
            subtitleWidth="55vw"
            onDark={true}
            textColor="#fff"
            hideDelimiter={true}
            fontFamily="Sainte Colombe, Helvetica, sans-serif"
            addClassName="slab-tile-highlight-section"
            fontWeight="400"
          />
        </section>

        {isDecorative ? (
          <div className={styles.mobileSearch}>
            <SearchFilter switchSelection={updateFilters} filtersState={state.filtersState} />
          </div>
        ) : (
          <SortFilter
            withoutNew={true}
            sortState={state.sortState}
            updateSortState={(update) =>
              (state.sortState = update) &&
              applyFilters(sortedInitialTiles, updateState, state, isDecorative)
            }
          />
        )}

        <section className={styles.content}>
          {isDecorative ? (
            <DecorativeFiltersSidebar
              tiles={sortedInitialTiles}
              switchSelection={updateFilters}
              filtersState={state.filtersState}
            />
          ) : (
            <CollapseFiltersSideBar
              materials={data.strapi.materials}
              isRenderInStock={isOutdoor}
              isRenderDealer={isField}
              isRenderFinish={!isOutdoor}
              tiles={sortedInitialTiles}
              filtersState={state.filtersState}
              switchSelection={updateFilters}
            />
          )}
          <div style={{ width: '100%' }}>
            <InfiniteScroll
              dataLength={state.loadedTiles.length}
              next={() => loadMore(PAGE_SIZE)}
              className={styles.slabCardsContainer}
              hasMore={state.loadedTiles.length !== state.tiles.length}
              loader={<h4>Loading...</h4>}
            >
              {state.loadedTiles.length ? (
                state.loadedTiles.map((p) => (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore

                  <ProductContainer
                    favouriteAPI={favouriteAPI}
                    isDecorative={isDecorative}
                    {...p}
                    key={`tile_${p.slug}_${p.id}`}
                    path={`/tile/${slug}/${p.id}`}
                    sortIndex={p.sortIndex}
                  />
                ))
              ) : (
                <div className={styles.cardPlaceholder}></div>
              )}
            </InfiniteScroll>
          </div>
        </section>
      </section>

      <Modal isOpen={state.isFilterPopupOpen} handleClose={switchFilterPopup}>
        <MobileFilters
          materials={data.strapi.materials}
          isRenderInStock={isOutdoor}
          isRenderDealer={isField}
          isRenderFinish={isField}
          tiles={sortedInitialTiles}
          filtersState={state.filtersState}
          switchSelection={updateFilters}
          onClearAll={clearFilter}
          onClose={switchFilterPopup}
          isDecorative={isDecorative}
        />
      </Modal>
    </Layout>
  );
};

// export const query = graphql`
//   query($collectionId: ID!) {
//     strapi {
//       materials {
//         id
//         name
//         sort_index
//       }
//       tilePage {
//         decorativeTile_backgroundImage {
//           url
//           alternativeText
//         }
//         fieldTile_backgroundImage {
//           alternativeText
//           url
//         }
//         outdoorTile_backgroundImage {
//           url
//           alternativeText
//         }
//       }
//       slabPage {
//         backgroundImage {
//           alternativeText
//           url
//         }
//       }
//       tiles(where: { tile_collection: { id: $collectionId } }) {
//         leadTime: LeadTime
//         range: Range
//         isInStock: inStock
//         externalLink: externalUrl
//         id
//         created_at
//         name
//         tile_collection {
//           name
//         }
//         description
//         material {
//           name
//           id
//         }
//         decorative_materials {
//           name
//           id
//         }
//         color {
//           id
//           name
//         }
//         color_secondary {
//           id
//           name
//         }
//         color_tertiary {
//           id
//           name
//         }
//         images {
//           url
//           caption
//         }
//         preview {
//           url
//         }
//         finishes: tile_finishes {
//           finish {
//             id
//           }
//           sizes
//           image {
//             url
//           }
//         }
//       }
//       finishes {
//         name
//         id
//         is_textured
//       }
//     }
//   }
// `;

interface Props {
  pageContext: {
    title: string;
    subtitle: string;
    description: string;
    slug: SlugType;
  };
  data: TilesResponse;
}

export function formatData(
  { strapi: { finishes, tiles } }: TilesResponse,
  sortState: 'A - Z' | 'Z - A',
): Tile[] {
  for (const tileInfo of tiles) {
    for (const f of tileInfo.finishes) {
      if (f.finish) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const finish = finishes.find((inf) => inf.id === f.finish?.id)!;
        f.name = finish.name;
        f.is_textured = finish.is_textured;
      }
    }
    tileInfo.colors = [tileInfo.color, tileInfo.color_secondary, tileInfo.color_tertiary].filter(
      (c) => c,
    );
  }

  return tiles.sort(createSortFn(sortState));
}

export default TileCollectionTemplate;
